import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Hint9 = () => (
  <Layout>
    <Header /> 

    <header className="masthead9">
    <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #9</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            Carefully painted, 
            <br></br>
            crafted with care.
            <br></br>
            <br></br>
            though slightly rusty
            <br></br>
            from the salt air.
            <br></br>
            <br></br>
            In a place for items
            <br></br>
            to be shipped.
            <br></br>
            <br></br>
            you will find the next 
            <br></br>
            stop on your trip.
            <br></br>
            <br></br>
            Most are white but 
            <br></br>
            this one is blue.
            <br></br>
            <br></br>
            open the lid to 
            <br></br>
            find your next clue.
            <br></br>
          </h2>
          <h3 className="roll-in-bottom mx-auto">
          Distance: 3.2 miles South</h3>
          <br></br>
          <a href="/clue9" className="btn btn-primary">
              Back to Clue
            </a>
            <br></br>
            <br></br>
          {/* <Scroll type="id" element="about">  
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Hint9;
